<template>
  <div class="home">
    <div class="header">
      <img :src="logoIcon" alt="河小象" class="logo-icon" @click.stop="toHome">
      <div class="nav-list-btn" @click.stop="openSideBar">
      </div>
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <transition name="slide-fade">
      <div class="side-bar" v-if="showSideBar == true">
        <div class="close-bar" @click.stop="closeSideBar"></div>
        <div class="nav-list">
          <router-link tag="div" class="nav-item" @click.native="routerTo" :to="{name: 'mHome'}">首页</router-link>
          <!-- <router-link tag="div" class="nav-item" @click.native="routerTo" :to="{name: 'mWrite'}">写字课</router-link> -->
          <!--<router-link tag="div" class="nav-item" @click.native="routerTo" :to="{name: 'mPoetry'}">每日古诗文</router-link>-->
          <!--<router-link tag="div" class="nav-item" @click.native="routerTo" :to="{name: 'mComposition'}">同步写作</router-link>-->
          <!-- <router-link tag="div" class="nav-item" @click.native="routerTo" :to="{name: 'mRead'}">经典名著阅读力</router-link> -->
          <!-- <router-link tag="div" class="nav-item" @click.native="routerTo" :to="{name: 'mNews'}">媒体报道</router-link> -->
        </div>
        <div class="service-info">
          <p class="service-name">客服电话</p>
          <p class="service-tel">{{ isHxxBaby ? '15314683489' : '400-163-3686' }}</p>
        </div>
      </div>
    </transition>
    <div class="footer">
      <div class="footer-box">
        <div class="footerLeft">
          <!-- <p @click.stop="goto('about')">关于我们</p> -->
          <!-- <p  @click.stop="goto('mBrand')">品牌简介</p> -->
          <!-- <p  @click.stop="goto('contact')">联系我们</p>
          <p  @click.stop="goto('mNews')">媒体报道</p>
          <p  @click.stop="goto('join')">加入我们</p>
          <p  @click.stop="goto('aggreement')">用户协议</p> -->
        </div>
        <div class="footerRight" v-if="!isHxxBaby">
            <p>微信公众号</p>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/wxapp_code.jpg" alt="微信公众号">
        </div>
      </div>
      <div class="footer-tip" v-if="isHxxBaby">
        <p>杭州河小象网络科技有限公司<br />
        <a href="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/hxx-yyzz.png" target="_blank">营业执照</a><br />
        <span @click.stop="goto('aboutHxx')">公司介绍</span><br />
        ICP 浙B2-20181300</p>
      </div>
      <div class="footer-tip" v-else>
        <p>杭州河象网络科技有限公司 (ICP 浙B2-20181300)</p>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002014018">
          <p>浙公网安备 33011002014018号</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'm',
  data () {
    return {
      logoIcon: 'https://xcx.alicdn2.hexiaoxiang.com/web_static/logo_m.png', //logo
      showSideBar: false
    }
  },
  computed: {    
    isHxxBaby: function() {
      let host = window.location.host;
      return host.match('hxxbaby.com');
    }
  },
  created() {
    this.setPageSize();
  },
  methods: {
    toHome() {
      this.$router.push({ path: "/m/" });
    },
    //路由跳转
    routerTo(path) {

      this.closeSideBar();
    },
    goto(path){
      let newPath = '/m/' + path;
      this.$router.push({path: newPath});
    },
    //打开侧栏
    openSideBar() {
      this.showSideBar = true;
    },
    //关闭侧栏
    closeSideBar() {
      this.showSideBar = false;
    },
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    }
  }
}
</script>

<style scoped>
.home {
  width: 100vw;
  padding-top: 1.173rem;
  display: flex;
  flex-direction: column;
}
.classBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 610px;
  background-color: #3285BB;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 1.173rem;
  box-shadow:1px 6px 10px rgba(64,63,65,0.1);
  z-index: 800;
  background-color: #fff;
}
.logo-icon {
  height: .48rem;
}
.nav-list-btn {
  position: absolute;
  top: 0.4rem;
  right: 0.8rem;
  width: .587rem;
  height: .4rem;
  background: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/menu-btn.png') no-repeat;
  background-size: .587rem .4rem;
}
.side-bar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 4.667rem;
  height: 100vh;
  background:rgba(255,255,255,1);
  box-shadow:-6px 0px 30px 0px rgba(195,195,206,0.23);
  z-index: 850;
}
.close-bar {
  position: absolute;
  top: 0.67rem;
  right: 0.73rem;
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/close-bar.png') no-repeat;
  background-size: 0.36rem 0.36rem;
}
.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.5rem;
  width: 100%;
}
.nav-item {
  box-sizing: border-box;
  width: 100%;
  font-size: .4rem;
  color: #444144;
  text-align: left;
  position: relative;
  margin: 0 .48rem 1.1467rem;
}
.nav-item:first-child{
  margin-top: .8rem;
}
.nav-item:not(:last-child)::after{
  position: absolute;
  content: '';
  background-color: #EEEEEE;
  width: 3.707rem;
  height: 1px;
  bottom: -.533rem;
  left: 0;
}
.service-info {
  margin-bottom: 1rem;
  color:#FF7041;
}
.service-name {
  font-size: .347rem;
}
.service-tel {
  font-size: .48rem;
  font-weight: bold;
  line-height:54px;
}
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to  {
  opacity: 0;
  -webkit-transform: translateX(50%);
  -moz-transform:translateX(50%);
  transform: translateX(50%);
}
.router-link-active{
  color: #FF7041;
  font-weight: bold;
}
/* 底部 */
.footer{
  background-color: #383B48;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .427rem 0 .36rem;
}
.footer-box{
    display: flex;
  justify-content: center;
  align-items: center;
}
.footerLeft{
  color: #fff;
  font-size: .32rem;
  display: flex;
  flex-wrap: wrap;
  width: 3.707rem;
  justify-content: space-between;
  margin-right: 1.547rem;
  margin-top: .24rem;
}
.footerLeft p:nth-child(3){
  margin-top: .733rem;
}
.footerLeft p:nth-child(4){
  margin-top: .733rem;
}
.footerLeft p:nth-child(5){
  margin-top: .733rem;
}
.footerLeft p:nth-child(6){
  margin-top: .733rem;
}
.footerRight p{
font-size: .32rem;
color: #FFFFFF;
}
.footerRight img{
  height: 2rem;
  margin-top: .24rem;
  border-radius: .107rem;
}
.footer-tip{
  font-size: .35rem;
  margin-top: .667rem auto 0;
  padding: .5rem 0;
}
.footer-tip p{
  color: #939393;
  margin: .533rem 0;
  line-height: 0.7rem;
}
.footer-tip a {
  color: #939393;
}
</style>
